@function mixblack($variable, $opacity) {
  @return unquote(
    'linear-gradient(to top,rgba(0, 0, 0,' + $opacity + '),rgba(0, 0, 0,' +
    $opacity + '))' + $variable
  );
}

@function mixwhite($variable, $opacity) {
  @return unquote(
    'linear-gradient(to top,rgba(255, 255, 255,' + $opacity +
    '),rgba(255, 255, 255,' + $opacity + ')) ' + $variable
  );
}
