
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.course-catalog {
  display: flex;
  flex-direction: row;
  width: 98%;
  max-width: 1280px;
  margin: 0 auto;

  &__courses-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 3px 7px;
  }

  &__courses {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-top: $separator-border-dark;
    padding-top: 10px;
  }

  &__course-card-wrapper {
    display: flex;
    flex: 0 0 33.333333%;
    align-items: stretch;
    margin-bottom: 15px;
    padding: 12px;

    @media screen and (max-width: $desktop-small-width) {
      flex: 0 0 50%;
      margin-bottom: 6px;
    }

    @media screen and (max-width: $tablet-width) {
      flex: 0 0 100%;
      margin-bottom: 3px;
    }
  }

  @media screen and (max-width: $tablet-width) {
    flex-direction: column;
    width: 100%;

    &__courses {
      border-top: none;
    }

    &__courses-wrapper {
      margin: 0;
      padding: 0 8px;
    }
  }

  &__courseevents-wrapper {
    margin-top: 0;
    padding: 0 10px;
  }

  &__course-calendar-card-wrapper {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    margin-bottom: 30px;
    padding-left: 30px;
  }

  &__calendar-title-month {
    margin-top: 60px;
    margin-bottom: 20px;
    text-transform: capitalize;

    &:first-child {
      margin-top: 30px;
    }
  }

  &__no-results {
    margin: auto;
    color: $color-grey-60;
    font-size: $font-size-l;

    &__term {
      font-style: italic;
      font-weight: bold;
    }
  }
}
