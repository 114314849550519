
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.employees-list {
  display: flex;
  flex-wrap: wrap;

  &__card-wrapper {
    overflow-x: hidden;
    display: flex;
    flex: 0 0 33.333333%;
    padding: 10px;
    &.absent{
      opacity: 0.4;
    }
    @media screen and (max-width: $desktop-medium-width) {
      flex: 0 0 50%;
    }

    @media screen and (max-width: $desktop-small-width) {
      flex: 0 0 100%;
      padding: 6px 10px;
    }

    @media screen and (max-width: $tablet-width) {
      flex: 0 0 50%;
    }

    @media screen and (max-width: $tablet-small-width) {
      flex: 0 0 100%;
    }
  }
}
