
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.highscore-list {
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  align-self: flex-start;

  &__title {
    margin: 14px 0;
    margin-left: 8px;
    color: $font-color-light;
    font-weight: 700;
    font-size: $font-size-xl;
  }

  &__content {
    flex: 1 1 auto;
    flex-direction: column;
    box-shadow: $card-small-shadow;
    border: $card-border;
    border-radius: 3px;
    background-color: $color-white;

    &__item {
      flex: 1 1 auto;
      margin: auto 0;
      font-size: $font-size-sm;
      line-height: 1.4;

      &__wrapper {
        display: flex;
        flex-direction: row;
        border-bottom: $card-separator;
        padding: 6px 14px;

        &:last-child {
          border-bottom: 0;
        }
      }

      &__avatar {
        flex: 0 0 110px;
        align-self: center;

        > :first-child {
          width: 65px;
          height: 65px;
          margin: auto;
        }
      }

      &__name,
      &__score {
        font-weight: 600;
      }

      &__score {
        color: $color-points;
      }

      &__details {
        color: $color-grey-70;
      }
    }
  }

  &__expand-button {
    width: 30px;
    height: 30px;
    margin: 20px auto 0;
    border: 1px solid $font-color-light;
    border-radius: 50%;
    background-color: $color-white;
    color: $font-color-light;
    font-size: 13px;
    padding-left: 2px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 3px;
    cursor: pointer;
  }
}
