
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.course-date {
  position: relative;
  display: flex;
  width: 100%;

  &__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-main-dark);

    &-day {
      font-size: $font-size-l * 2;
      line-height: $font-size-l * 2;
    }

    &-month {
      font-size: $font-size-s;
    }
  }

  &__details {
    margin: 0 30px;
  }

  &__title {
    margin-bottom: 5px;
    font-size: $font-size-m;
  }

  &__location,
  &__hours {
    font-size: $font-size-s;
  }

  &__button {
    align-self: center;
    margin-right: 0;
    margin-left: auto;
  }
}
