
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.dropdown-select {
  $this: &;

  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-self: flex-start;
  box-shadow: 0 2px 3px 0 rgba(100, 100, 100, 0.1);
  border: 1px solid #e5e3e1;
  border-radius: 6px;
  padding: 10px 0;
  background-color: $color-grey-97;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: $color-white;
  }

  &__label {
    flex: 1 1 auto;
    padding: 0 24px;
    color: $font-color-light;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
  }

  &__arrow {
    display: block;
    flex: 0 0 auto;
    margin-right: 0.8em;
    text-align: center;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 500;
    flex-direction: column;
    min-width: 100%;
    margin-top: 4px;
    border: $card-border;
    border-radius: 6px;
    background-color: $color-white;

    &__content {
      flex-direction: column;
      flex-wrap: nowrap;

      overflow-y: auto;
    }

    &-rightalign {
      right: 0;
      left: auto;
    }

    &-fullwidth {
      position: absolute;
      overflow-y: auto;
      display: flex;
      flex: 0 0 100%;
      flex-direction: column;
      margin-top: 4px;
      width: 100%;
      border: $card-border;
      border-radius: 12px;
      background-color: $color-white;

      &-wrapper {
        position: relative;
        z-index: 500;
        width: 100%;
      }
    }

    &-rows {
      #{$this} {
        &__dropdown__column {
          &-wrapper {
            flex-direction: column;
            flex-wrap: nowrap;
          }

          &__content {
            padding: 0 40px;
            padding-bottom: 14px;

            @media screen and (max-width: $tablet-width) {
              padding: 0;
            }
          }
        }
      }
    }

    &__column {
      flex: 1 1 auto;
      border-right: $separator-border;
      padding: 8px 16px;

      &:last-child {
        border-right: none;
      }

      @media screen and (max-width: $tablet-width) {
        padding: 8px 8px;
      }

      &-wrapper {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
      }

      &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 10px;
        padding-left: 20px;
        color: $font-color-light;
        font-weight: 700;
        font-size: 1.1em;
      }

      &__content {
        display: flex;
        flex-direction: column;
      }
    }

    &__item {
      flex: 1 1 auto;
      min-width: min-content;
      margin: auto 0;
      border-top: $card-border;
      padding: 10px 24px;
      font-weight: normal;
      font-size: $font-size-sm;
      line-height: 1.4;
      white-space: nowrap;
      cursor: pointer;

      &:first-child {
        border-top: none;
      }

      &:hover {
        background-color: $color-grey-97;
      }

      &-active {
        font-weight: 700;
      }

      &-filtered {
        color: $color-grey-60;
      }
    }

    &-fullwidth &__item {
      border-top: none;
    }
  }
}
