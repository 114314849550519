
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';
@import '~@routes/reports/variables.scss';

@keyframes notice-bubble {
  0% {
    transform: scale(1);
  }

  50% {
    background-color: #ff8300;
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.camp-extra {
  $this: &;

  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 100px;
  padding: 40px 80px;
  position: relative;

  &__top-section {
    margin-bottom: 40px;
    padding-bottom: 0 !important;

    &-bg {
      border-bottom: 1px solid #d2d2d1;
      background-color: #e3e3e0;
      transition: max-height 0.4s ease-out;
      overflow: hidden;
      max-height: max-content;
      box-shadow: 0 2px 2px 0 rgba(100, 100, 100, 0.1);

      &--collapsed {
        max-height: 128px !important;
      }
    }
  }

  &__top-toggle {
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    padding: 2px 20px;
    background-color: #e3e3e0;
    border: 1px solid #d2d2d1;
    border-top: none;
    color: #6a6868;
    transition: background-color 0.115s ease-out;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(100, 100, 100, 0.1);

    &:hover {
      background-color: #dbdbdb;
    }

    i {
      transform: scaleY(0.8);
    }
  }

  @media screen and (max-width: $large-screen) {
    padding: 40px 20px;
  }

  @media screen and (max-width: $medium-screen) {
    padding: 40px 4px;
  }

  &__columns {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 -40px;

    > * {
      padding: 0 40px;
    }
  }

  &__statistics-cards {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -80px;
    margin-bottom: 20px;

    > * {
      margin-right: 80px;
    }

    &--wrapper {
      display: flex;
      flex: 1 1 auto;
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      margin-top: 20px;
    }

    @media screen and (max-width: $large-screen) {
      margin: 0 -10px;
      margin-right: -40px;
      margin-bottom: 20px;

      > * {
        margin-right: 40px;
        padding: 0 10px;
      }
    }

    @media screen and (max-width: $medium-screen) {
      flex-direction: column;
      margin-right: 0;

      > * {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  &__highscore-lists {
    @media screen and (max-width: $xlarge-screen) {
      margin: 0 -10px;

      > * {
        padding: 0 10px;
      }
    }

    @media screen and (max-width: $medium-screen) {
      flex-direction: column;

      > * {
        width: 100%;
      }
    }
  }

  &__notice-bubble {
    i {
      position: absolute;
      top: 0;
      right: -16px;
      background-color: $color-grey-60;
      width: 1.4em;
      line-height: 1.4;
      border-radius: 50%;
      text-align: center;
      overflow: visible;
      color: #fff;
      font-size: 10px;
      cursor: pointer;
    }

    &--warn {
      i {
        background-color: #e84a39;
      }

      &-animate {
        i {
          animation-name: notice-bubble;
          animation-duration: 0.8s;
          animation-timing-function: ease-in-out;
          animation-delay: 0;
          animation-iteration-count: infinite;
          animation-direction: forward;
          will-change: transform, background-color;
        }
      }
    }
  }

  &__checkbox {
    flex: 1 1 auto;
    margin-top: 0;
    margin-bottom: 20px;
    margin-left: auto;
    color: $font-color;
    font-size: $font-size-ml;
  }

  &__logo {
    align-self: baseline;
    margin-right: auto;
    margin-bottom: 20px;
  }

  &__last-updated-cards {
    position: absolute;
    max-width: 330px;
    width: max-content;
    position: absolute;
    right: -30px;
    top: -22px;
    z-index: 1000000;
    transform: translateX(100%);

    &__card {
      text-align: left;
      background-color: #fff;
      padding: 8px;
      padding-left: 40px;
      margin: 8px 0;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.2);

      .camp-extra__notice-bubble i {
        animation: none;
        top: 12px;
        left: 12px;
        right: auto;
        bottom: auto;
      }

      &__title {
        font-size: 0.9rem;
        color: $color-grey-60;

        &--warn {
          color: #e84a39;
        }
      }

      &__timestamp {
        margin-top: 4px;
        font-size: 1rem;
        color: $color-grey-40;
        font-weight: bold;
        line-height: 1.4;

        &__time {
          font-weight: normal;
        }
      }

      &__warn {
        font-size: 1rem;
        color: #f03535;
        margin-top: 10px;
        margin-bottom: 4px;
      }
    }
  }

  &__section-header {
    flex: 0 1 auto;
    align-self: baseline;
    margin: 60px 0;
    margin-right: auto;
    color: $font-color-light;
    font-weight: 700;
    font-size: $font-size-xxl;
    position: relative;

    &::after {
      content: '';
      bottom: -10px;
      height: 4px;
      width: 120px;
      background-color: $color-orange;
      display: block;
      position: absolute;
    }
    &-center {
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__section {
      display: flex;
      position: relative;
    }

    &--metrics {
      margin: 40px auto;
      margin-bottom: 30px;
      position: relative;
    }

    &-wrapper {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-right: 0;
      margin-left: auto;
    }

    &__right {
      display: flex;
      flex: 0 0 auto;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  &__top-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__link {
    display: block;
    color: #4ab7ed !important;
    font-size: $font-size-ml;
    line-height: 0;
    text-align: right;
    cursor: pointer;
  }

  &__excel-link {
    margin-top: 20px;
  }

  &__highscore-links {
    flex: 0 0 auto;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 66px;
  }

  &__dropdowns {
    position: sticky;
    top: 0;
    z-index: 3;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__bg {
      position: fixed;
      right: 0;
      left: 0;
      z-index: -1;
      display: none;
      height: 66px;
      background-color: rgba(255, 255, 255, 0.95);
    }
  }

  &__shop-select {
    flex: 0 0 auto;
    margin-top: 10px;
    margin-right: auto;
  }

  &__datepicker {
    position: absolute;
    top: 10px;
    right: 0;
  }

  &__chart-wrapper {
    display: flex;
    flex-direction: row;
    min-height: 280px;
    margin: 0 -80px;

    .extra-chart {
      max-width: 500px;
      height: 346px;
      margin: 0 auto;
    }

    @media screen and (max-width: $large-screen) {
      flex-direction: column;
      margin: 0 -40px;

      .extra-chart {
        width: 70%;
        height: 310px;
        margin-right: auto;
        margin-bottom: 20px;
        margin-left: auto;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (max-width: $medium-screen) {
      margin: 0;

      .extra-chart {
        width: 100%;
      }
    }
  }

  &__void {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    width: fit-content;
    margin: auto;
    color: $color-grey-40;
    font-size: $font-size-xl;
  }

  &__no-active {
    opacity: 0.4;
    margin-top: 20px;
    text-align: center;
  }
}
