
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.role-form {
  display: flex;
  flex-direction: column;

  &__input-wrapper {
    width: 100%;
  }

  &__input {
    width: 100%;
  }

  .form-input{
    &__label {
      display: block;
    }
    input[type="checkbox"] {
      width: auto;
      margin-left: 4px;
    }
  }

  .form-multiselect {
    .rw-widget {
      height: 95%;
    }
  }



  &__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    text-align: right;
    margin-bottom:20px;
  }
}
