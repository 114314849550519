
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.employee-add {
  &__card {
    position: relative;
    display: flex;
    box-shadow: $card-shadow;
    margin: 10px;
    border-radius: 8px;
    padding: 40px 20px;
    background: white;
  }

  &__buttons-wrapper {
    position: absolute;
    right: 40px;
    bottom: 40px;
    padding-top: 20px;
  }

  &__bottom-checkbox-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 70px 0;
    padding-left: 30px;
  }

  &__checkbox-label {
    margin-left: 10px;
  }

  &__form {
    display: flex;
    justify-content: center;

    &-inner-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 70%;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    margin: 40px 0;
    background-color: $color-grey-70;
  }

  .hlp {
    padding: 7px;
    color: $color-grey-60;
    font-size: $font-size-s;

    strong {
      color: black;
    }
  }

  &__input-wrapper {
    width: 45%;
  }

  &__input-full-wrapper {
    width: 100%;
  }

  &__input {
    border-radius: 10px;

    &--multiselect {
      /* Removes scrollbar for Firefox */
      overflow: -moz-scrollbars-none;

      /* Removes scrollbar for IE +10 */
      -ms-overflow-style: none;
      max-height: 200px;
      border-color: $color-gray-fields;
      padding: 10px 0 30px;

      &::-webkit-scrollbar {
        /* Removes scrollbar for Chrome, Safari and newer versions of Opera */
        width: 0 !important;
      }
    }
  }

  &__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center;
  }

  @media screen and (max-width: 1100px) {
    &__form-inner-wrapper {
      width: 80%;
    }
  }

  @media screen and (max-width: 1000px) {
    &__form-inner-wrapper {
      width: 90%;
    }
  }

  @media screen and (max-width: $desktop-small-width) {
    &__form {
      width: 100%;

      &-inner-wrapper {
        flex-direction: column;
        width: 70%;
      }
    }

    &__bottom-checkbox-wrapper {
      padding-left: 10px;
    }

    &__input-wrapper {
      width: 100%;
    }
  }

  @media screen and (max-width: $tablet-small-width) {
    &__form-inner-wrapper {
      width: 90%;
    }
  }
}
