
          $REACT_APP_PUBLIC_URL: "/";
        
.extra-employees-chart {
  position: relative;
  overflow: visible;
  display: block;
  flex: 1 1 auto;
  width: auto;
  margin: 0;
  margin-top: auto;
  margin-bottom: 20px;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
