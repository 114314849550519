
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.news-list__news-body a {
  display: inline-block;
}

.news-list__news-body a,
.btn {
  box-sizing: border-box;
  box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.4);
  border-radius: 5px;
  padding: 8px 15px;
  background: var(--color-main-dark);
  color: white;
  font-weight: 700;
  font-size: $font-size-s;
  line-height: 1.5;
  cursor: pointer;
  &:hover {
    box-shadow: none;
    box-shadow: 1px 1px 4px #fff;
  }
  &--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0;
  }

  &--light-background {
    background-color: $color-grey-99;
    color: var(--color-main-dark);
  }

  &--flat {
    box-shadow: none;
    background-color: transparent;
    color: var(--color-main-dark);
  }
}
