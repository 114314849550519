
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.my-education-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  @include md {
    margin-top: 80px;
  }
  padding: 0 20px;

  &__section {
    width: 100%;
    margin: 15px 0 0;
    transition: flex 0.3s ease-out;
  }

  &__section-option {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: $tablet-width) {


    &__section {
      width: 100%;
      margin: 5px 0 0;
    }
  }
}
