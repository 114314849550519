
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.person-avatar {
  $avatar-size: 40px;

  position: relative;
  align-items: baseline;
  width: $avatar-size;
  height: $avatar-size;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: block;
    display: flex;
    border-radius: inherit;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__initials {
    margin: auto;
    color: $color-white;
    font-weight: 700;
    font-size: $avatar-size * 0.7;
    user-select: none;
    pointer-events: none;
  }

  &__avatar-layer {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__avatar,
  &__avatar-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__notification-count {
    position: absolute;
    bottom: -6px;
    left: -6px;
    z-index: 100;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
    margin-bottom: 14px;
    margin-left: 14px;
    border-radius: 50%;
    background: #a20202;
    color: $color-white;
    font-size: 0;
    line-height: 0;
    text-align: center;

    &-enter-active {
      transition: all 400ms;
    }

    &-enter-active,
    &-enter-done {
      opacity: 1;
      width: 22px;
      height: 22px;
      margin-bottom: 0;
      margin-left: 0;
      font-size: 12px;
      line-height: 22px;
    }
  }
}
