
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.employee-message {
  display: flex;
  flex-direction: column;
  width: calc(100% - 310px);
  box-shadow: $card-shadow;
  border-radius: 8px;
  padding: 20px;
  background: white;

  &__form-wrapper {
    border-radius: 4px;
    background: $color-grey-95;
  }

  @media screen and (max-width: $desktop-small-width) {
    width: 100%;
    margin: 30px 0;
  }
}
