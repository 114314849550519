
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.courses-menu {
  $this: &;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 20px;

  &__menu-button {
    display: flex;
    margin-right: auto;
    margin-left: 30px;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 16px;
    background: #fff;
    color: $color-grey-20;
    font-weight: 600;
    font-size: 1.1em;
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.3);
      color: #000;
    }

    &__active {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &:hover {
        border: 1px solid #fff;
        color: #000;
      }
    }

    &--light {
      color: $color-grey-50;
    }
  }

  &__menu-button-icon {
    margin-right: 10px;
  }

  &__competencegroups-list-wrapper {
    position: absolute;
    top: 24px;
    right: 0;
    left: 0;
    display: flex;
  }

  .sorting-active {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 120px;
    border: 1px solid #ccc;
    padding: 2px;
    padding-right: 24px;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      background-color: White;
    }

    .sorting-options {
      position: absolute;
      top: 100%;
      left: 0;
      display: block;
      min-width: 100%;
      box-shadow: 2px 2px 2px #ccc;
      margin: 0;
      margin-top: 5px;
      padding: 0;
      background-color: white;
    }



    svg {
      position: absolute;
      right: 6px;
      margin-top: 3px;
    }
  }

  &__sorting-wrapper {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 0;
    margin-left: auto;
    color: #666;
    font-weight: 500;
    font-size: $font-size-s;
    text-indent: 4px;

    &__button {
      margin: 0 2px;
      padding: 6px 6px;
      white-space: nowrap;
    }

    @media screen and (max-width: $tablet-width) {
      &__button {
        padding: 0;
        height: 44px;
        margin: 0;
      }

      &__sort-method-link {
        padding: 12px;
        border-bottom: 1px solid #9e9d9d;
      }

      &__sort-method-link-last {
        border-bottom: none;
      }

    }

    &__button,
    a,
    a:active,
    a:hover,
    a:visited {
      display: block;
      color: $color-grey-30;
      cursor: pointer;

      i {
        display: inline;
        padding-left: 2px;
      }

      &.active {
        border-bottom: 2px solid var(--color-action);
        padding-bottom: 4px;
        color: var(--color-action);
        font-weight: 800;

        @media screen and (max-width: $tablet-width) {
          border-bottom: none;

          a {
            text-decoration: underline;
          }
        }
      }


    }
  }

  &__buttons-wrapper {
    margin-top: auto;
  }

  &__chipses-next {
    flex: 1 1 auto;
    margin-top: 15px;
    margin-right: auto;
    margin-left: 0;

    .sorting-active {
      padding: 5px 7px;
      padding-right: 24px;
      font-weight: 700;
      font-size: $font-size-m;
    }
  }

  &__subcategory-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 10px;
    color: $color-grey-80;
    font-size: $font-size-l;
    text-align: left;
    cursor: auto;
  }

  @media screen and (max-width: $tablet-width) {
    &__toggle-sort-type-icon {
      position: relative;
      left: 27px;
      bottom: 6px;
    }

    .sorting-active .sorting-options {
      border: 1px solid #9e9d9d;
      box-shadow: none;
    }

  }


  &--mobile {
    margin-bottom: 0;

    .sorting-active {
      width: auto;
      border: none;
      padding-right: 10px;
      color: $color-grey-30;
      font-weight: 400;

      &.active {
        background: transparent;
      }
    }

    .sorting-active {
      min-width: 55px;
    }

    #{$this} {
      &__menu-button {
        margin-left: 10px;
      }

      &__buttons-wrapper {
        flex-direction: column-reverse;
        margin: auto 0;
      }

      &__sorting-wrapper {
        margin: auto 0;
        margin-right: auto;
        margin-left: 10px;
      }
    }
  }
}
