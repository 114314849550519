
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.edit-pass {
  padding-top: 0px;
  overflow-y: auto;
  .form-input {
    padding: 0;
  }

  .hlp {
    margin-bottom: 10px;
    color: $color-grey-60;
    font-size: $font-size-s;

    strong {
      font-size: $font-size-sm;
      color: black;
    }
  }

  .old-password {
    margin-bottom: 20px;

    .form-input {
      width: 100%;

      @include sm() {
        width: 41%;
      }
    }
  }

  &__heading {
    color: initial;
    margin: 30px 0 0;
    font-size: $font-size-l;
  }

  &__heading-username {
    margin-top: 10px;
  }

  &__heading-description {
    margin-bottom: 4px;
  }

  &__description {
    margin: 5px 0 30px;
    color: $color-grey-30;
    font-size: 1rem;
  }

  &__description-norsk-butikkdrift {
    margin-top: 4px;
  }

  &__inputs-wrapper {
    display: flex;
    width: 100%;

    :first-child {
      margin-right: 20px;
    }

    @media screen and (max-width: $mobile-width) {
      flex-direction: column;

      :first-child {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  &__buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
}
