
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.person-form {
  display: flex;
  flex-wrap: wrap;

  &__input-wrapper {
    flex: 1 0 50%;
  }

  &__input {
    width: 100%;
  }

  &__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 30px;
    padding-right: 5px;
    text-align: right;
  }

  @media only screen and (max-width: $tablet-width) {
    justify-content: center;

    &__input-wrapper {
      flex: 0 0 100%;
    }

    &__submit-button-wrapper {
      width: auto;
    }
  }
}
