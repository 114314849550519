
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.form-ckeditor {
  display: flex;
  flex-direction: column;
  padding: $form-field-wrapper-padding;

  &__label {
    padding: 0 4px;
    font-weight: 700;
    font-size: $font-size-s;
    line-height: 24px;
  }

  &__editor {
    display: flex;
    border: 1px solid grey;
    border-radius: 4px;
  }

  &__error {
    color: #c22;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
}
