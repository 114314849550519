
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.checklist-item {
  $this: &;

  display: flex;
  align-items: center;
  margin-top: 10px;

  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 2px solid $color-grey-50;
    border-radius: 100%;
    background: $color-grey-99;
    font-size: 15px;
  }

  &__title {
    margin-left: 10px;
  }

  &--passed {
    #{$this}__status {
      border-color: #2d6017;
      background-color: #9cc79c;
      color: #2d6017;
    }
  }
}
