
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.messages-list {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  box-shadow: $card-shadow;
  border: 1px solid $color-grey-85;
  border-radius: 10px;
  padding: 20px;
  background: white;

  .nomsg {
    padding-top: 50px;
    color: #ccc;
    font-size: $font-size-l;
    text-align: center;
    text-transform: uppercase;
  }
}

.message {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-bottom: 1px solid $color-grey-90;
  }

  &--expanded {
    background: darken($color-white, 2%);

    &:first-child {
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }

    &:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }

  &__header {
    display: flex;
    align-items: baseline;
    padding: 10px 22px;

    &:hover {
      background: darken($color-white, 4%);
      cursor: pointer;
    }
  }

  &__info {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  &__subject {
    overflow: hidden;
    min-width: 0;
    font-weight: 700;
    font-size: $font-size-m;
    line-height: 1.5;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--expanded {
      white-space: pre-wrap;
    }
  }

  &__date {
    margin-right: 10px;
    color: $color-grey-60;
    font-size: $font-size-s;
    text-align: right;
  }

  &__collapse {
    display: flex;
    justify-content: center;
    align-items: baseline;
    border: none;
    padding: 10px;
    background: none;
    color: $color-grey-30;
    font-size: 16px;
    cursor: pointer;
  }

  &__meta {
    display: flex;
    flex-direction: column;
    margin-top: 10px 0;
    padding: 0 22px;
    line-height: 1.5;
  }

  &__recipent {
    margin-right: 10px;
    color: $color-grey-40;
    font-size: $font-size-s;
    font-size: 14px;

    div {
      display: inline-block;
      width: 40px;
      margin-right: 10px;
      text-align: right;
    }

    span {
      font-weight: 600;
    }

    p {
      font-weight: 600;
    }

    a {
      text-decoration: none;

      &,
      &:active,
      &:visited {
        color: var(--color-link);
      }
    }
  }

  &__content {
    padding: 0 22px;
    line-height: 1.2;
    white-space: pre-wrap;
  }
}
