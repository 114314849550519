
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.message-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: $form-field-wrapper-padding;
  }

  &__button {
    display: flex;
    margin: 10px 0 20px 10px;
    text-align: center;
    vertical-align: middle;

    &:hover {
      box-shadow: none;
    }
  }

  &__input-wrapper {
    margin: 0 10px;
    margin-bottom: 20px;
  }

  &__submit-button {
    margin: 10px;
    margin-bottom: 20px;
  }
}
