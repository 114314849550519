
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';
@import '~@styles/functions.scss';

.form-multiselect {
  padding: $form-field-wrapper-padding;

  &__label {
    padding: 0 4px;
    font-weight: 700;
    font-size: $font-size-s;
    line-height: 24px;
  }

  &__input {
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 0;
    font-size: $font-size-m;

    .rw-state-focus > &,
    .rw-state-focus > &:hover {
      box-shadow: none;
      border: 1px solid grey;
    }

    &--error {
      border-color: #c22;
      color: #c22;
    }
  }

  .rw-select-list {
    height: 200px;
  }

  .rw-list-option {
    &.rw-state-focus {
      border-color: var(--color-main-dark);
    }

    &:hover {
      background: mixwhite(var(--color-main-dark), 0.9);
    }
  }

  &__error {
    color: #c22;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
}
