
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.recommended-competences {
  display: flex;
  flex-direction: column;

  &__title {
    margin: 10px;
    margin-top: 30px;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: $font-size-xl;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
  }

  &__card-wrapper {
    display: flex;
    flex: 1 0 50%;
    align-items: stretch;

    @media screen and (max-width: 680px) {
      flex: 0 0 100%;
    }
  }

  &__card {
    display: flex;
    flex: 1 1 auto;
    box-shadow: $card-shadow;
    margin: 10px;
    border-radius: 6px;
    background-color: var(--color-main-dark);
    color: white;
  }

  &__card-cover {
    display: flex;
    flex: 0 0 100px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    min-height: 100px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-size: 50px;

    i {
      margin-left: 10px;
    }
  }

  &__card-description {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-width: 200px;
    padding: 10px;
  }

  &__card-title {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: $font-size-l;
    line-height: 1.5;
  }

  &__card-subtitle {
    font-size: $font-size-m;
    line-height: 1.5;
  }

  &__status {
    display: flex;
    justify-content: center;
    color: var(--color-main-dark);
    font-size: $font-size-m;
  }
}
