
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.competence-card {
  box-shadow: $card-shadow;
  margin: 10px;
  border: 1px solid $color-grey-85;
  border-radius: 8px;
  padding: 20px;
  background: white;

  @media screen and (max-width: $tablet-width) {
    margin: 10px 0;
  }

  &.compact {
    .course-preview__action-button {
      box-shadow: none;
      border: 1px solid #ccc;
      background-color: #efefef;
      color: #666;

      &:hover {
        background-color: inherit;
      }
    }
  }

  &.employee {
    border: none;

    .grid-x {
      width: 100%;

      .course-grade {
        font-size: $font-size-s;
        text-align: right;
      }
    }
  }

  .sub {
    h3 {
      border-bottom: 1px solid #ccc;
      font-size: $font-size-sm;
    }
  }

  .button {
    text-align: right;
  }

  .competence-card-sub {
    margin: 4px 0;
    padding: 4px;
    font-size: $font-size-sm;
  }

  .small-header {
    width: 100%;
    font-size: $font-size-s;
  }
}
