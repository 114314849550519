
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.login-page {
  &__container {
    width: 100%;
    height: 100%;
    background: white !important;
    font-family: "PT Sans Narrow", sans-serif;

    .grid-x {
      height: 100%;
    }

    .img {
      position: relative;
      background-position: right top;
      background-size: cover;
    }

    .red-warning {
      padding-left: 10px;
      color: red;
      font-weight: 900;
    }

    .dark {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.6;
      width: 100%;
      height: 100%;
      background-color: black;
    }

    .faq {
      margin-top: 30px;
      color: $color-grey-40;
      font-size: $font-size-m;
      text-align: center;
      cursor: pointer;

      button {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .login_box {
      width: 100%;
      @include md {
        width: 500px;
      }
      margin: 0 auto;
      margin-top: 18%;
      font-size: $font-size-m;
      text-align: center;

      .logo {
        width: 180px;
        margin-bottom: 20px;
        text-align: center;
      }

      .text {
        font-size: $font-size-l;
        text-align: left;

      }
    }

    button.back {
      color: #25a9e1;
    }
  }

  &.app-wrapper__form-wrapper {
    overflow-y: auto;
    display: block;
    flex: 0 1 auto;
    width: 90%;
    max-width: 350px;
    box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.4);
    border-radius: 10px;
    background: white;
  }

  &__title {
    margin: 17px;
  }

  &__form {
    margin: 10px;
    margin-bottom: 17px;
  }
}
