
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.form-input {
  padding: $form-field-wrapper-padding;

  &__label {
    padding: 0 4px;
    font-weight: 700;
    font-size: $font-size-s;
    line-height: 24px;
  }

  &__input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $color-grey-50;
    border-radius: 10px;
    padding: 10px;
    font-size: $font-size-m;

    &--error {
      border-color: #c22;
      color: #c22;
    }
  }

  &__error {
    color: #c22;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
}
