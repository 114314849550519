
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.employee-edit {
  display: flex;
  justify-content: space-between;

  &__left-column {
    float: left;
    clear: both;
    width: 275px;
    min-width: 225px;
    max-width: 300px;
  }

  &__right-column {
    width: 100%;
    padding-left: 310px;
  }

  &__card,
  &__avatar-card,
  &__actions-card {
    display: flex;
    box-shadow: $card-shadow;
    padding: 20px 20px 40px 20px;
    background: white;
  }

  &__modal-content {
    overflow: visible;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    width: 80vw;
    max-width: 800px;
    padding: 30px 40px 20px;
    border-radius: $border-radius-modal;
    max-height: 80vh;
    overflow-y: auto;
    @include sm() {
      max-height: 60vh;
    }
    .person-competence-form {
      overflow: auto;
      flex: 1 1 auto;
    }

    &-onthejob {
      flex-direction: column;

      @include sm() {
        width: 35vw;
        min-width: 440px;
      }

      @include md() {
        width: 79vw;
        min-width: 440px;
      }

      @include lg() {
        width: 60vw;
        min-width: 440px;
      }
    }

    &-title {
      flex: 0 1 auto;
      margin-top: 0;
      margin-bottom: 5px;
      color: var(--color-main-dark);
      font-size: $font-size-l * 1.2;

      &--small {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: $font-size-l * 0.9;
      }
    }

    &-paragraph {
      margin: 10px 0;
      color: $color-grey-30;
      font-size: $font-size-m * 0.9;
    }

    &-button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 75px;
    }

    &-button {
      padding: 10px 30px;
    }
  }

  &__modal-hidden {
    display: none;
  }

  &__avatar {
    &-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 0 20px;
    }

    &-image {
      width: 40%;
    }

    &-name {
      margin-top: 5px;
      color: var(--color-main-dark);
      font-weight: 500;
      font-size: $font-size-l;
    }
  }

  &__form {
    flex: 1 1 auto;
  }

  &__actions {
    &-button {
      width: 100%;
      height: 60px;
      text-align: left;
      cursor: pointer;

      i {
        margin: 0 20px 0;
      }
    }

    &-card {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    &-item {
      width: 100%;
      border-bottom: 1px solid $color-grey-80;
      font-size: $font-size-m * 0.9;

      &:last-of-type {
        border: 0;
      }
    }
  }

  &__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center;
  }

  @media screen and (max-width: $tablet-width) {
    flex-direction: column;

    &__left-column {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      order: 2;
      width: 100%;
      min-width: inherit;
      max-width: inherit;
    }

    &__right-column {
      padding-left: 0;
    }

    &__avatar {
      width: 40%;
    }

    &__actions {
      width: 100%;

      &-title {
        margin-top: 0;
        text-align: center;
      }
    }

    &__left-column {
      flex-direction: column;
    }

    &__actions {
      width: 100%;
      margin: 20px 0;
    }

    &__avatar {
      width: 100%;

      &-image {
        width: 30%;
      }
    }

    &__modal {
      width: 95vw;
      min-width: unset;
      margin: 0 auto;
      padding: 15px 15px 20px;
      border-radius: 8px;
    }


    .form-input__label {
      max-width: 40%;
    }
  }
}
