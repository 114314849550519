
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.competences-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  box-shadow: $card-shadow;
  border: 1px solid $color-grey-85;
  border-radius: 10px;
  padding: 20px;
  background: white;

  &__competence-group {
    display: flex;
    flex-direction: column;

    &:last-child,
    &:last-child &.competence:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }

  &__empty-state {
    color: $color-grey-60;
    text-align: center;
  }

  &__competence-group-header {
    margin: 20px 15px;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: $font-size-xl;
  }

  @media screen and (max-width: $tablet-width) {
    border-radius: 8px;
  }
}

.competence {
  display: flex;
  flex-direction: column;
  margin: 0 -20px;
  border-bottom: inset 1px #e9e9e9;
  padding: 8px 40px;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: $color-grey-97;
    cursor: pointer;
  }

  &__info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
  }

  &--expanded {
    box-shadow: 0 0 2px 1px rgba(100, 100, 100, 0.2);
    margin-right: -22px;
    margin-bottom: 6px;
    margin-left: -22px;
    border-top: 1px $color-grey-85;
    border-bottom: inset 1px $color-grey-85;
    padding-right: 42px;
    padding-left: 42px;
    background: $color-grey-97;

    .competence__info {
      margin-bottom: 5px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__name {
    font-weight: 700;
    font-size: $font-size-m;
    line-height: 1.5;
  }

  &__meta {
    display: flex;
    line-height: 1.5;
  }

  &__progress {
    margin-right: 10px;
    font-weight: 700;
    font-size: $font-size-s;
  }

  &__grade {
    margin-right: 10px;
    font-weight: 700;
    font-size: $font-size-s;
  }

  &__date {
    font-size: $font-size-s;
  }

  &__collapse {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 10px;
    background: none;
    color: $color-grey-30;
    font-size: 16px;
    cursor: pointer;
  }

  &__description {
    margin-top: 10px;
    line-height: 1.2;
  }

  @media only screen and (max-width: $tablet-width) {
    padding: 6px 20px;
  }

  @media only screen and (max-width: $mobile-width) {
    &__collapse {
      padding-right: 0;
    }
  }
}
