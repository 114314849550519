
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.profile-base-info {
  $this: &;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-shadow: $card-shadow;
  margin: 0 10px;
  margin-bottom: 30px;
  border: 1px solid $color-grey-85;
  border-radius: 10px;
  padding: 30px;
  background: white;

  a {
    color: inherit;
  }

  &.small {
    padding-bottom: 80px;
  }

  &__top-section {
    display: flex;
    margin-bottom: 10px;
  }

  &.cv {
    text-align: center;

    a {
      color: black;
      font-weight: 600;
    }
  }

  .subtitle {
    margin-right: 30px;
    font-size: $font-size-sm;
  }

  .bottom-task-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: 1px solid #ddd;
    padding-right: 20px;
    background-color: #f5f5f5;
    color: #666;
    font-size: $font-size-s;
    line-height: 40px;
    text-align: right;
  }

  &__avatar-wrapper {
    overflow: visible;
    display: flex;
    flex: 0 0 90px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-right: 20px;
    text-align: center;
    cursor: pointer;
  }

  &__avatar-loading {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #e9e9e9;
  }

  &__avatar {
    $avatar-size: 80px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: $avatar-size;
    height: $avatar-size;
    border: none;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-main-dark);
    font-size: $avatar-size;
  }

  &__name {
    margin-bottom: 1em;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: $font-size-l;
    line-height: 1;
    white-space: nowrap;
  }

  &__education-and-focus-area-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;

    .progress__bar-overall {
      width: 100%;
      max-width: 300px;
    }
  }

  &__education-status-container,
  &__focus-area-container {
    display: flex;
    flex: 1 1 200px;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 40px;

    .progress {
      font-size: $font-size-ml;

      &__bar-complete {
        background-color: $color-progressbar-fill-2;
      }
    }
  }

  &__education-progresses,
  &__focus-areas {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__title {
    margin-top: 0.6em;
    color: $color-grey-60;
    font-weight: 700;
    font-size: $font-size-m;
  }

  &__bottom-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &__positions {
    display: block;

    &:last-child {
      border-bottom: none;
    }

    .heading {
      display: flex;
      flex-direction: row;
      margin-left: 10px;

      h2 {
        font-weight: 400;
      }

      @media screen and (max-width: $mobile-width) {
        flex-direction: column;

        ul.tabs {
          margin-top: -30px;
        }
      }
    }

    .profile-base-info {
      padding-bottom: 80px;
    }

    .allok {
      padding-bottom: 20px;
      color: #aaa;
      text-align: center;

      i {
        font-size: 36px;
      }

      h2 {
        color: #aaa;
        font-weight: 400;
        font-size: 18px;
      }
    }

    .section {
      margin-bottom: 30px;
    }

    .section-position {
      margin-top: 10px;
      margin-bottom: 30px;
      border-bottom: $separator-border;
      padding-bottom: 30px;

      &:last-child {
        @include sm() {
          margin-bottom: 0;
        }

        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  &__position-title {
    display: flex;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: $font-size-l;
  }

  &__position-subtitle {
    margin-right: 40px;
    color: $font-color;
    font-size: $font-size-sm;
  }

  &__position-competences-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &__position-competences-group-title {
    display: flex;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: $font-size-m;
    line-height: 1.5;
  }

  &__position-competence {
    display: flex;
    margin: 3px;
    color: darkgrey;
    font-size: $font-size-m;
    line-height: 1.5;
  }

  &__competencelevel {
    display: block;
    margin-top: 4px;

    .progress {
      width: 90%;

      &__bar-overall {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: $tablet-small-width) {
    &__position .progress {
      width: 100%;
    }

    &__education-status-container {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: $mobile-width) {
    &__top-section {
      flex-direction: column;
    }

    &__avatar-wrapper {
      margin: 0 auto;
      margin-bottom: 25px;
    }

    &__education-status-container {
      margin: 0 auto;
    }
  }

  &__loaders {
    $loaders: &;

    width: 100%;
    max-width: 100%;
    margin: 10px;

    &__title {
      width: 150px;
      height: 16px;
      background: $color-grey-95;
    }

    &--courses {
      width: 100%;
      height: 75px;
      box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
      margin: 10px 10px 34px 65px;
      border-radius: 5px;
      padding: 10px;
      background: $color-white;

      #{$loaders} {
        &__text {
          width: 200px;
          height: 14px;
          margin-top: 5px;
          background: $color-grey-95;

          &--short {
            width: 100px;
          }
        }
      }
    }

    &--competences {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      #{$loaders} {
        &__title {
          padding-bottom: 20px;
        }
      }

      #{$this} {
        width: 100%;
        height: 170px;
        margin: 0;

        &--small {
          width: calc(50% - 20px);
          margin-top: 40px;
        }
      }
    }
  }
}
