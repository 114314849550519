
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.course-sign {
  align-self: center;
  margin-right: 0;
  margin-left: auto;
  white-space: nowrap;

  &__drop {
    z-index: 100 !important;
  }

  &__dropdown {
    position: relative;
    z-index: 10;

    &-btn {
      border-radius: 10px;

      &--main {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 25px;
        padding-left: 30px;
      }

      &--chevron {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: 1px solid #fff;
        padding-right: 10px;
        padding-left: 10px;
      }
    }

    &-options {
      position: absolute;
      display: flex;
      flex-direction: column;

      @include sm() {
        right: 0;
      }

      width: 200px;
      box-shadow: $card-shadow;
      margin-top: 5px;
      border: 1px solid $color-grey-40;
      border-radius: 10px;
      background: $color-grey-99;
      font-size: $font-size-s;
    }

    &-option {
      width: 100%;
      border-radius: 0;
      border-top: 1px solid $color-grey-70;
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: 500;
      text-align: left;
      cursor: pointer;

      i {
        margin: 0 15px;
      }

      &:first-of-type {
        border-top: 0;
      }
    }
  }

  &__modal {
    position: relative;
    width: 100%;
    border-radius: 10px;

    @include md() {
      width: 50vw;
    }

    padding: 30px 40px;
    background: $color-grey-99;

    &-title {
      margin-top: 0;
    }

    &-subtitle {
      margin: 0;
      font-size: $font-size-l;
    }

    .form-multiselect {
      padding: 0;
    }

    .modal-form {
      margin-bottom: 50px;

      &__buttons {
        position: absolute;
        right: 40px;
        bottom: 30px;

        .btn {
          padding: 8px 40px;
        }
      }
    }

    &-results {
      &:nth-last-child(1) {
        margin-bottom: 60px;
      }

      &__container {
        display: flex;
        flex-direction: column;
        margin: 40px;
        margin-right: 0;
        margin-bottom: 20px;
      }

      &__item {
        margin-top: 5px;
        margin-left: 20px;

        &--correct {
          i {
            margin-right: 8px;
            color: $color-green;
            font-size: $font-size-m * 0.9;
          }
        }

        &--error {
          i {
            margin-right: 15px;
            color: darken($color-red, 20%);
          }
        }
      }
    }
  }

  &__search-wrapper {
    margin-right: -10px;
    margin-left: -10px;
  }
}
