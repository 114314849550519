
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.person-onthejob-form {
  display: flex;
  flex-direction: column;

  &__input-wrapper {
    width: 100%;
  }

  &__input {
    width: 100%;
  }

  &__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    text-align: right;
  }
}
