
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.course-dates {
  width: 100%;

  &__item {
    display: flex;
    box-shadow: $card-shadow;
    margin-top: 25px;
    border-radius: 10px;
    padding: 15px;
    padding-left: 30px;
    background-color: $color-grey-99;
  }
}
