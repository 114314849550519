
          $REACT_APP_PUBLIC_URL: "/";
        
@import './common/styles/libraries-config';
@import '~@node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@node_modules/react-widgets/dist/css/react-widgets.css';
@import './common/styles/main';

// prettier-ignore
body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;

  &,
  &:active,
  &:visited {
    color: var(--color-link);
  }
}
