
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.employee-report {
  &__card {
    position: relative;
    overflow-x: auto;
    width: auto;
    box-shadow: $card-shadow;
    margin: 1%;
    border-radius: 8px;
    padding: 40px 20px;
    background: white;
  }

  &__title {
    min-width: 630px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__table {
    width: 100%;
    min-width: 630px;
    border: 0 solid;
    border-collapse: collapse;
    font-size: $font-size-s;
    table-layout: auto;

    th {
      position: relative;
      border-bottom: 1px solid #666;
      padding: 6px;
      text-align: left;
      white-space: nowrap;
    }

    tr {
      border-bottom: 1px solid #ccc;

      td {
        min-width: fit-content;
        padding: 8px;
      }
    }
  }

  &__buttons-wrapper {
    position: absolute;
    right: 40px;
    bottom: 40px;
    padding-top: 20px;
  }

  &__bottom-checkbox-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 70px 0;
    padding-left: 30px;
  }

  &__checkbox-label {
    margin-left: 10px;
  }

  &__form {
    display: flex;
    justify-content: center;

    &-inner-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 70%;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    margin: 40px 0;
    background-color: $color-grey-70;
  }

  &__input-wrapper {
    width: 45%;
  }

  &__input {
    border-radius: 10px;

    &--multiselect {
      height: 300px;
      border-color: $color-gray-fields;
      padding: 10px 0 30px;
    }
  }

  &__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center;
  }

  @media screen and (max-width: 1100px) {
    &__form-inner-wrapper {
      min-width: 80%;
    }
  }

  @media screen and (max-width: 1000px) {
    &__form-inner-wrapper {
      min-width: 90%;
    }
  }

  @media screen and (max-width: $desktop-small-width) {
    &__form {
      min-width: 100%;

      &-inner-wrapper {
        flex-direction: column;
        min-width: 70%;
      }
    }

    &__bottom-checkbox-wrapper {
      padding-left: 10px;
    }

    &__input-wrapper {
      min-width: 100%;
    }
  }

  @media screen and (max-width: $tablet-small-width) {
    &__form-inner-wrapper {
      min-width: 90%;
    }
  }
}

.employees-page.report-page {
  width: 80%;
  min-width: fit-content;
  padding: 0;

  @media screen and (max-width: $tablet-small-width) {
    width: auto;
    min-width: auto;
  }
}
