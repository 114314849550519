
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.course-catalog-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;

  .breadcrumbs {
    max-width: 1024px;
    padding: 0;
  }

  &__section {
    width: 100%;
    margin: 15px 0 0;
    transition: flex 0.3s ease-out;
  }

  @media only screen and (max-width: $tablet-width) {
    margin-top: 10px;

    &__section {
      width: 100%;
      margin: 0;
    }
  }
}
