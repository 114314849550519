
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.course-card,
.course-card-loading {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;

  &__cover {
    position: relative;

    &--image {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      width: 100%;
      height: 100%;
      border: 10px solid $color-white;
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      color: var(--color-main-dark);
      font-size: 60px;
    }

    &--icon {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 4px 10px;
      background-color: var(--color-main-dark);
      color: $color-white;
      font-weight: 600;
      font-size: 10px;
      line-height: 1;
    }
  }

  &__content {
    padding: 0 10px;

    &--time {
      padding-top: 10px;
      color: var(--color-action);
      font-weight: normal;
      font-size: $font-size-s;
    }

    &--title {
      overflow-wrap: break-word;
      margin-bottom: 0;
      padding-top: 10px;
      color: $color-grey-20;
      font-size: $font-size-ml;
      line-height: 1.2;
      word-wrap: break-word;
      hyphens: auto;
    }

    &--description {
      display: flex;
      flex-direction: column;
    }

    &--subtitle {
      padding-top: 0;
      color: #555;
      font-weight: 400;
      font-size: $font-size-sm;
      line-height: 1.2;

      p {
        margin: 0;
        margin-top: 10px;
      }
    }
  }

  @media screen and (max-width: $tablet-width) {
    flex-grow: 0;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 20px;

    &__content {
      width: 60%;
      padding: 0;
      padding-left: 10px;

      &--title {
        color: $color-grey-40;
        font-size: $font-size-m;
      }

      &--subtitle {
        flex-shrink: 0;
        width: 100%;
        margin-top: 10px;
        color: $color-grey-40;
      }

      &--time {
        font-size: $font-size-s;

        .fas {
          color: $color-grey-40;
        }
      }
    }

    &__cover {
      width: 35%;
    }

    &__description {
      margin: 0;
    }
  }
}

.course-card-loading {
  .course-card__content {
    overflow: hidden !important;
    flex: 1 1 100%;

    svg {
      flex: 0 0 auto;
      width: 250px;
      height: 150px;
    }
  }
}
