
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.events-list {
  flex: 1 1 auto;
  padding: 10px;

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    color: var(--color-main-dark);
    line-height: 1.2;

    &__day {
      font-size: 2em;
    }

    &__month {
      margin-top: -4px;
      font-size: $font-size-s;
    }
  }

  &__avatar {
    flex: 0 0 40px;
    margin-top: 5px;
    margin-right: 25px;
    margin-left: 10px;

    .person-avatar {
      width: 45px;
      height: 45px;
    }
  }

  &__card {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    box-shadow: $card-small-shadow;
    border-radius: 5px;
    padding: 10px;
    background-color: $color-white;

    &__contents {
      flex: 1 1 auto;
    }
  }

  &__text {
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -1000px;
      left: -1000px;
      display: block;
      border-top: $separator-border;
    }

    &:first-child {
      margin-top: 0;
      padding-top: 0;

      &::after {
        border-top: none;
      }
    }

    &__title {
      color: var(--color-main-dark);
      font-weight: 600;

      &:hover {
        color: var(--color-main-dark);
        text-decoration: underline;
      }
    }

    &__description {
      font-size: $font-size-s;
      line-height: 1.4;
    }

    &__location {
      font-size: $font-size-s;
    }
  }

  &__person-name {
    margin-bottom: 6px;
    color: $font-color;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }
}
