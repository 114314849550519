
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';
@import '~@routes/reports/variables.scss';

.statistics-card {
  $this: &;

  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  height: 125px;
  box-shadow: $card-small-shadow;
  margin-top: auto;
  margin-bottom: 20px;
  border: $card-border;
  border-radius: 3px;
  padding: 16px 10px;
  padding-right: 80px;
  background-color: $color-white;

  &__wrapper {
    display: flex;
    flex: 0 1 auto;

    @media screen and (max-width: $medium-screen) {
      margin: 10px 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    width: 65px;
    height: 65px;
    margin: auto;
    border-radius: 50%;
    color: $color-white;

    i {
      width: 100%;
      font-size: 32px;
      text-align: center;
    }

    &-wrapper {
      flex: 0 0 auto;
      margin: 0 25px;
      margin-right: 50px;
    }

    @media screen and (max-width: $medium-screen) {
      width: 60px;
      height: 60px;

      i {
        font-size: 28px;
      }

      &-wrapper {
        margin: 0 20px;
      }
    }
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin-right: 10px;
    line-height: 1.4;

    @media screen and (max-width: $medium-screen) {
      line-height: 1.3;
    }

    &__title {
      font-size: $font-size-sm;
      text-transform: uppercase;
    }

    &__score {
      font-weight: 700;
      font-size: 2.5em;
      line-height: 1.3;
      white-space: nowrap;
    }

    &__details {
      color: $font-color-light;
      font-size: $font-size-s;
    }

    &__columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;

      &__item {
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        margin-right: 20px;
        line-height: 1.4;

        &:last-child {
          flex: 1 1 auto;
        }

        > * {
          flex: 0 0 0;
        }
      }
    }
  }
}
