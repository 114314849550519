
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.employees-list-container {
  flex: 1 1 auto;


  .employees-list {
    margin-top: 30px;
  }

  &__search-bar-and-actions {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
  }

  &__search-bar-wrapper {
    flex: 1 1 auto;
  }

  &__actions-container {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    padding: 10px;
  }

  &__add-employee-button {
    border: none;
    padding: 10px;
    background: none;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__add-employee-button-icon {
    margin-right: 5px;
  }

  &__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center;
  }
}
