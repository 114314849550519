
          $REACT_APP_PUBLIC_URL: "/";
        
@import '~@styles/variables.scss';

.breadcrumbs {
  width: 98%;
  max-width: 1280px;
  margin: 0;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 10px;
  color: #666;
  font-weight: 700;
  line-height: 1.5;

  a {
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-decoration: none;
  }
}

.narrow {
  margin-top: 0;

  .breadcrumbs {
    max-width: 1024px;
  }
}
